import "./Navbar.css"
import React, { useState } from 'react'
import { Link } from "react-router-dom"
import mainlogo from "../images/techdarshak.png"

import { FaBars, FaTimes } from "react-icons/fa"

const Navbar = () => {



    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    const [color, setColor] = useState(false);
    const changeColor = () => {
        if (window.scrollY >= 100) {
            setColor(true);
        } else {
            setColor(false);
        }
    };



    return (
        <>
            <div className="w-[499px] h-[480px] rounded-[499px] border border-black bg-[#acacf799] filter blur-[100px] absolute left-[40rem] -top-[370px] hidden lg:flex" />
            <div className={`mheader xl:mx-36 relative`} >
                <a href="/" className="ml-2 md:ml-0">
                    <img className="h-[60px] mb-4 md:h-[100px] hover:animate-pulse" src={mainlogo} />
                </a>
                <ul className={click ? "nav-menu active " : "nav-menu  "}>

                    <li>
                        <a href="/" onClick={() => setClick(!click)}><h3 className="hover:text-hoverNavLinks transition-all duration-300">Home</h3></a>
                    </li>


                    <li>
                        <a href="/about" onClick={() => setClick(!click)}><h3 className="hover:text-hoverNavLinks transition-all duration-300">About</h3></a>
                    </li>

                    <li>
                        <a href="/" onClick={() => setClick(!click)}><h3 className="hover:text-hoverNavLinks transition-all duration-300">Pricing</h3></a>
                    </li>

                    <li>
                        <a href="/" onClick={() => setClick(!click)}><h3 className="hover:text-hoverNavLinks transition-all duration-300">Portfolio</h3></a>
                    </li>


                    <li>
                        <a href="/contactUs" onClick={() => setClick(!click)}><h3 className="cbtn hover:bg-hoverNavLinks transition-all duration-300">Contact Us</h3></a>
                    </li>
                </ul>

                <div className="hamburger pr-4 md:mr-0" onClick={handleClick}>
                    {/* {click ? (condition1) : (condition2)} */}
                    {click ? (<FaTimes size={20} style={{ color: "white" }} />) : (<FaBars size={20} style={{ color: "white" }} />)
                    }
                </div>

            </div>
        </>

    );
};

export default Navbar;