import React from 'react'
import flash from "../images/flash1.svg"

const Technology = (props) => {
    const { text, name, des } = props
    return (
        <div class="p-2 gap-y-4 flex flex-col items-end justify-center  w-[279px] min-h-[289px] h-auto
    bg-[#6161fd] border border-gray-200 rounded-2xl shadow dark:border-gray-700 card relative wow animate__animated animate__fadeInUp" data-wow-duration="1.5s" data-wow-offset="90" >
            <img className='absolute -top-7 left-28' height={85} width={55} src={flash} />
            <div className='flex flex-col items-center gap-y-2 min-h-[220px] h-full justify-between mt-16 '>
                <p class=" font-medium text-base capitalize text-[14px] text-white min-h-max h-auto text-center">{text}</p>
                <div className='flex flex-col items-center justify-center mx-auto text-sm font-medium text-center text-white bg-[#020225]/60 rounded-2xl focus:ring-4 focus:outline-none focus:ring-blue-300 flex-end px-3 pt-1.5'>
                    <h1 href="#" class="text-xl">
                        {name}
                    </h1>
                    <span className='text-[10px]'>{des}</span>
                </div>
            </div>
        </div>
    )
}

export default Technology