import React, { useState } from "react";
import "./Smallcards.css";
import Cube from "../images/Business Need/3dcube.png";
import TCube from "../images/Business Need/box-2.png"
import Insta from "../images/Business Need/instagram.png"
import Flash from "../images/Business Need/flash.png"
import Traingle from "../images/Business Need/triangle.png"
import Deploy from "../images/Business Need/image.png"
import Card from "./Card";

const Smallcards = () => {
  const data = [
    {
      id: 1,
      name: "Software Development",
      image: Cube
    },
    {
      id: 2,
      name: "Web Development",
      image: TCube
    },
    {
      id: 3,
      name: "Mobile App Development",
      image: Insta
    },
    {
      id: 4,
      name: "UI/UX Design",
      image: Flash
    },
    {
      id: 5,
      name: "Full Stack Development",
      image: Traingle
    },
    {
      id: 6,
      name: "Deployment & Hosting",
      image: Deploy
    }

  ]
  const [obj, setObj] = useState(data)
  return (
    <div className="flex flex-col gap-y-28 my-40 relative">
      <div className="w-[350px] h-[380px] rounded-[499px] border border-black bg-[#acacf799] backdrop-filter-none blur-[180px] absolute -top-32 right-0 hidden lg:block" />
      <div className="w-[499px] h-[280px] rounded-[499px] border border-black bg-[#acacf799] blur-[100px] absolute top-32 -left-[450px] hidden lg:block" />
      <div className="header1">
        <h1 className="text-[40px]  md:text-6xl lg:w-[880px] text-center mx-auto wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="20">We build What Your Business Need</h1>
      </div>

      <div className="">
        <div className="flex flex-col lg:flex lg:flex-row lg:flex-wrap justify-center items-center md:mx-[280px]">
          {obj.map((item) => {
            return <Card {...item} />
          })}
        </div>
      </div>
    </div>
  );
};

export default Smallcards;
