import React, { useEffect } from "react";
import img from "../images/rlvnt.png";
// import img from "../images/wheelFull.png";

const Homecontent = () => {
 
  return (
    <>
      <div className="flex justify-between w-full h-screen">
        <div className="w-[499px] h-[480px] rounded-[499px] border border-black bg-[#acacf799] filter blur-[120px] md:blur-[80px] absolute -left-[20rem] top-80 lg:block" />
        <div className="flex items-center">
          <div className="xl:ml-36 md:ml-10 mb-20 md:text-left text-center flex flex-col justify-center relative">
            <p className="sm:text-[66px] text-[42px] md:leading-[80px]  font-extrabold  text-[#FFF3F3] dark:text-white wow animate__animated animate__backInLeft" data-wow-duration="1s" >We Delivery <br />Software Driven <br /> Innovation</p>
            <p className="mt-6 md:w-[660px] md:text-xl text-lg font-normal text-[#FFFFFF]  dark:text-white capitalize mx-4 md:mx-0 wow animate__animated animate__backInLeft" data-wow-duration="1s" >We’re a software and services company that builds and transforms businesses using advanced technology, data, and innovation.</p>
          </div>
          <div className="absolute right-0 md:top-56 top-[300px] h-full wow animate__animated animate__pulse" data-wow-duration="1s" >
            <img id="lottie" src={img} className="relative opacity-20 md:opacity-100 w-[562px] h-[502px] xl:w-[562px] xl:h-[732px]" alt="rlvnt logo" />
          </div>
          {/* <div className="absolute overflow-hidden -right-80 md:top-56 top-[300px] h-full wow animate__animated animate__backInRight" data-wow-duration="1s" >
            <img id="lottie" src={img} className="relative opacity-20 md:opacity-100 w-full h-full" alt="rlvnt logo" />
          </div> */}

        </div>
      </div>
    </>
  );

};

export default Homecontent;
