import React from 'react'
import path from '../images/path.png'
import './Homecontent2.css'
// import img from '../images/rlvnt.png';
import i2 from '../images/Customized Solutions/im1.png'
import i1 from '../images/Customized Solutions/im2.png'
import Solutions from '../components/Solutions'
import Technology from '../components/Technology'
import { email } from '../utils/constants'


const Homecontent2 = () => {
    const hrefOfEmail = `mailto:${email}`
    const sol = [
        {
            id: 1,
            image: i1,
            text: "Inventory Management System"

        },
        {
            id: 2,
            image: i2,
            text: "Custom ERP Systems"
        }
    ]
    const tech = [
        {
            id: 1,
            text: "Tech Darshak's guidance transformed our digital strategy, resulting in a significant boost in online visibility and sales.",
            name: "Zaid Qureshi",
            des: "CTO : Rexton IT services LLP"
        },
        {
            id: 2,
            text: "Their expertise in identifying the right influencers helped us reach our target audience effectively, driving remarkable brand engagement.",
            name: "Sanjana Ingle",
            des: "Founder : Ira Blogging"
        },
        {
            id: 3,
            text: "Tech Darshak's personalized approach and innovative solutions revolutionized our marketing campaigns, surpassing our expectations and delivering exceptional results.",
            name: "Rushikesh Ghodke",
            des: "RealEstate Businessman"
        },
        {
            id: 4,
            text: "the team at Techdarshak identified the exact problem i was facing with inventory and provided me with best solution in minimum time.",
            name: "Sangram Patil",
            des: "MSME Owner"
        }
    ]
    return (
        <div className=''>
            <>
                <div className='my-36 relative'>
                    <div className="w-[299px] h-[250px] rounded-r-3xl rounded-[499px] border border-black bg-[#acacf799] filter blur-[100px] absolute top-[450px] right-0 hidden lg:block" />
                    <h1 className='text-center md:text-[50px] text-4xl  mb-12 wow animate__animated  animate__fadeIn' data-wow-offset="80" data-wow-duration="1.5s">Customised Solutions</h1>
                    <div className='flex md:flex-row flex-col justify-center gap-x-10 m-10 gap-y-10  mt-28'>
                        {sol.map((item) => {
                            return <div className='md:w-[380px] md:h-[379px]'>
                                <Solutions {...item} />
                            </div>
                        })}
                    </div>
                </div>
                <div className='my-36 relative'>
                    <div className="w-[299px] h-[390px] rounded-r-3xl rounded-[499px] border border-black bg-[#acacf799] filter blur-[130px] absolute top-[300px] -left-20  lg:block" />
                    <h1 className='text-center md:text-[50px] text-4xl wow animate__animated  animate__fadeIn' data-wow-duration="1s" data-wow-offset="90">Client Stories</h1>
                    <div className='mt-8 md:w-[780px] mx-auto'>
                        <p className='text-center text-xl text-white mx-4 md:mx-0 capitalize wow animate__animated  animate__zoomIn' data-wow-duration="1s" data-wow-offset="90">Listen to what our clients say about their amazing journey with Techdarshak and how we built awesome products for them.</p>
                    </div>
                    <div className='flex lg:flex-wrap flex-col lg:flex-row justify-center gap-16 items-center mt-28'>
                        {tech.map((item) => {
                            return <Technology {...item} />
                        })}
                    </div>
                </div>
                <div className='lg:ml-96 my-24 flex flex-col gap-y-20 mx-auto relative'>
                    <div className="w-[249px] h-[340px] rounded-r-3xl rounded-[499px] border border-black bg-[#acacf799] filter blur-[130px] absolute top-40 right-0 hidden lg:block" />
                    <h1 className='md:text-6xl md:leading-normal text-4xl text-center lg:text-left font-bold lg:w-[650px] capitalize wow animate__animated  animate__flipInX' data-wow-duration="1s" data-wow-offset="100"> Let’s build a revolutionary product for your business. </h1>

                    <div className='bg-[#2424CB]  filter blur-[50px] lg:w-[250px] lg:h-[80px] w-[220px] h-[60px] left-16 md:left-0 absolute bottom-0 z-0' />

                    <button className='bg-connectButton hover:bg-hoverConnectButton lg:w-[250px] lg:h-[80px] w-[220px] h-[60px] text-white lg:text-3xl text-xl rounded-3xl font-bold md:mx-auto mx-auto lg:mx-0 shadow-buttonShadow z-50 wow animate__animated animate__flash' data-wow-duration="1s" data-wow-offset="100" >
                        <a href={hrefOfEmail}>Lets Connect.. </a>
                    </button>
                </div>
            </>
        </div>
    )
}

export default Homecontent2