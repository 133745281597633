import './App.css';
import About from "./routes/About"
import Pricing from "./routes/Pricing"
import Portfolio from "./routes/Portfolio"
import ContactUs from "./routes/Contactus"
import Home from './routes/Home';
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import { useEffect } from 'react';
import WOW from "wowjs"
import Footer from './components/Footer';

function App() {
  useEffect(()=>{
    new WOW.WOW().init();
  },[])
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/pricing' element={<Pricing />} />
        <Route path='/portfolio' element={<Portfolio />} />
        <Route path='/contactus' element={<ContactUs />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
