import React from 'react'
import "./Cards2.css"
import iraBlog from "../images/Trusted by/iraBlog.png"
import lyffix from "../images/Trusted by/Lyffix.png"
import wiweave from "../images/Trusted by/wiweave (3).png"
import kalasawa from "../images/Trusted by/kalasawa.png"
import atomicat from "../images/Trusted by/atomicat.png"
import nova from "../images/Trusted by/nova.png"
import sangram from "../images/Trusted by/sangram.png"


const Cards2 = () => {
  return (
    <div className='flex flex-col justify-center w-full my-36 relative gap-y-20'>
      <div className="w-[499px] h-[280px] rounded-[499px] border border-black bg-[#acacf799] blur-[130px] absolute top-48 right-0 hidden lg:block" />

      <div className="flex justify-center">
        <h1 className='text-[40px]  md:text-6xl wow animate__animated animate__fadeIn' data-wow-duration="1s" data-wow-offset="50">Trusted By</h1>
      </div>
      <div className='flex justify-center items-center bg-[#6161FD] py-1 overflow-hidden group'>
        <div className='flex justify-center items-center animate-loop-scroll-mobile md:animate-loop-scroll-desktop group-hover:paused w-full'>
          <div className='flex md:flex-row flex-row gap-x-20 md:gap-x-40 gap-y-10 mx-10 justify-center w-full items-center'>
            <img src={iraBlog} className='md:w-56 md:h-56 w-32 h-32 wow animate__animated animate__fadeInDown object-contain' data-wow-duration="1s" data-wow-offset="50" />
            <img src={lyffix} className='md:w-36 md:h-36 w-20 h-20 wow animate__animated animate__fadeInDown object-contain' data-wow-duration="1s" data-wow-offset="50" />
            <img src={wiweave} className='md:w-60 md:h-28 w-28 h-28 wow animate__a nimated animate__fadeInDown object-contain' data-wow-duration="1s" data-wow-offset="50" />
            <img src={kalasawa} className='md:w-40 md:h-40 w-28 h-28 wow animate__animated animate__fadeInDown object-contain' data-wow-duration="1s" data-wow-offset="50" />
            <img src={atomicat} className='md:w-56 md:h-56 w-28 h-28 wow animate__animated animate__fadeInDown object-contain' data-wow-duration="1s" data-wow-offset="50" />
            <img src={nova} className='md:w-56 md:h-56 w-28 h-28 wow animate__animated animate__fadeInDown object-contain' data-wow-duration="1s" data-wow-offset="50" />
            <img src={sangram} className='md:w-56 md:h-56 w-28 h-28 wow animate__animated animate__fadeInDown object-contain' data-wow-duration="1s" data-wow-offset="50" />
          </div>
        </div>
      </div>

    </div>
  )
}

export default Cards2