import React from 'react'
import footerlogo from "../images/footerlogo.png"
import { FaFacebookF, FaGoogle, FaLinkedin, FaTwitter } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io";

const Footer = () => {
    const handleGoToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    return (

        <footer className=" bg-black ">
            <div className="mx-auto w-full max-w-screen-2xl p-4  lg:py-10 ">
                <div className="md:flex md:justify-between ">
                    <div className="-mt-6 md:mb-0 w-fit py-7 md:py-0">
                        <a href="/" className="flex items-center">
                            <img src={footerlogo} className="h-28" alt="footerlogo" />
                            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Techdarshak</span>
                        </a>
                    </div>
                    <div className="text-center flex flex-col gap-y-4 md:mt-[1.2rem]">
                        <h2 className="text-xl font-semibold text-[#FFFFFF] uppercase dark:text-white">Quick Links</h2>
                        <div className='grid grid-cols-2 sm:grid-cols-2'>
                            <div className='text-center'>
                                <ul className="text-white font-medium">
                                    <li className="m-1">
                                        <a href="/about" className="hover:underline ">About</a>
                                    </li>
                                    <li className="m-1">
                                        <a href="/" className="hover:underline">Pricing</a>
                                    </li>
                                    <li className="m-1">
                                        <a href="/" className="hover:underline">Portfolio</a>
                                    </li>
                                    <li className="m-1">
                                        <a href="/" className="hover:underline">careers</a>
                                    </li>

                                </ul>
                            </div>
                            <div className='text-center '>
                                <ul className="text-white font-medium">
                                    <li className="m-1">
                                        <a href="/" className="hover:underline">Join Us</a>
                                    </li>
                                    <li className="m-1">
                                        <a href="/" className="hover:underline">Hire Us</a>
                                    </li>
                                    <li className="m-1">
                                        <a href="/" className="hover:underline">Insights</a>
                                    </li>
                                    <li className="m-1">
                                        <a href="/" className="hover:underline">Open Source</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="">
                    <ul>
                        <li className="m-1 text-white font-medium md:text-end md:mr-16 text-center ">
                            <a href="/contactUs" className="hover:underline ">Contact us</a>
                        </li>
                    </ul>

                </div>
                <div className="flex flex-col md:flex-row justify-between mb-20 mt-4 gap-x-4 items-center w-full">
                    <div className='flex flex-row gap-x-4 items-center'>

                        <FaFacebookF className='h-[2.5rem] w-[2.5rem] cursor-pointer' color='#3479FF' />

                        <FaTwitter className='h-[2.5rem] w-[2.5rem] cursor-pointer' color='#3479FF' />

                        <a target='_blank' href='https://www.instagram.com/techdarshak_/'>
                            <AiFillInstagram className='h-[2.5rem] w-[2.5rem] cursor-pointer' color='#3479FF' />
                        </a>

                        <a target='_blank' href='https://in.linkedin.com/company/techdarshak-software-llp'>
                            <FaLinkedin className='h-[2.5rem] w-[2.5rem] cursor-pointer' color='#3479FF' />
                        </a>

                        <a target='_blank' href='https://www.google.com/search?q=techdarshak+software&sca_esv=584463452&source=hp&ei=q2xdZYnxGtGo1e8Pgoyu8Ac&iflsig=AO6bgOgAAAAAZV16u-2exbJRPURvc-MUahU9xafEO64Q&ved=0ahUKEwjJ8qvbzNaCAxVRVPUHHQKGC34Q4dUDCAo&uact=5&oq=techdarshak+software&gs_lp=Egdnd3Mtd2l6IhR0ZWNoZGFyc2hhayBzb2Z0d2FyZTIFECEYoAEyBRAhGKABSOMFUABYAHAAeACQAQCYAZYBoAGWAaoBAzAuMbgBA8gBAPgBAvgBAQ&sclient=gws-wiz#ip=1'>
                            <FaGoogle href='' className='h-[2.5rem] w-[2.5rem] cursor-pointer' color='#3479FF' />
                        </a>

                        <IoLogoWhatsapp className='h-[2.5rem] w-[2.5rem] cursor-pointer' color='#3479FF' />
                    </div>
                    <div>
                        <button className='text-white w-52 px-2 capitalize h-7 rd-20 bg-gray-600 text-center  rounded-full mx-auto mt-8 md:my-0' onClick={handleGoToTop}>Go to Top </button>
                    </div>

                </div>
                <div className="flex md:flex-row sm:items-center sm:justify-betweenm flex-col">
                    <span className="text-sm text-white text-center sm:text-center dark:text-white">© 2023 <a className=""> Techdarshak Software LLP. All Rights Reserve. Privacy Policy Terms of Use</a>
                    </span>
                </div>
            </div>
        </footer>

    )
}

export default Footer