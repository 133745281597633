import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Homecontent from '../pages/Homecontent'
import Homecontent2 from '../pages/Homecontent2'
import Smallcards from '../pages/Smallcards'
import Cards2 from '../pages/Cards2'
import Card3 from '../pages/Card3'

const Home = () => {

  return (
    <div>
      <Homecontent />
      <Smallcards />
      <Cards2 />
      <Card3 />
      <Homecontent2 />
    </div>
  )
}

export default Home;

