import React, { useState } from "react";
import "./Card3.css";
import Sun from "../images/Technologies we use/sun.png";
import Flash2 from "../images/Technologies we use/flash2.png"
import Node1 from "../images/Technologies we use/tag-right2.png"
import Dwifi from "../images/Technologies we use/home-wifi2.png"
import laravel from "../images/Technologies we use/laravel1.png"
import react from "../images/Technologies we use/React1.png"
import nextjs from "../images/Technologies we use/nextjs1.png"
import mysql from '../images/Technologies we use/mysql.svg'
import mongodb from '../images/Technologies we use/mongodb.svg'
import { TbBrandLaravel } from "react-icons/tb";
import Card from "./Card";

const Card3 = () => {
  const data = [
    {
      id: 1,
      name: "React Native",
      image: Sun
    },
    {
      id: 2,
      name: "Flutter",
      image: Flash2
    },
    {
      id: 3,
      name: "NodeJS",
      image: Node1
    },
    {
      id: 4,
      name: "DevOps",
      image: Dwifi
    },
    {
      id: 5,
      name: "Laravel",
      image: laravel
    },
    {
      id: 5,
      name: "ReactJS",
      image: react
    },
    {
      id: 5,
      name: "NextJS",
      image: nextjs
    }

  ]
  const [obj, setObj] = useState(data)
  return (
    <div className="flex flex-col my-12 gap-y-28">
      <div className="text-center mx-auto">
        <div className="">
          <h1 className="text-[40px] md:text-6xl lg:w-[780px] mx-auto wow animate__animated animate__fadeInDown head" data-wow-duration="1s" data-wow-offset="60">Technologies We Use</h1>
        </div>
        <div className="text-center mx-auto w-fit">
          <p className="md:w-[780px] mx-2 md:text-xl text-lg capitalize wow animate__animated  animate__pulse text-white text-center mt-8" data-wow-duration="1s" data-wow-offset="60">We Work With Cutting-Edge Technologies Designed To Solve Your Product Requirements. Focus On Finding solutions For Your Business Needs While We Handle The Technical Aspect.</p>

        </div>

      </div>
      <div className="">
        <div className="flex flex-col lg:flex lg:flex-row lg:flex-wrap justify-center items-center md:mx-[280px]">
          {obj.map((item) => {
            return <Card {...item} />
          })}
        </div>
      </div>
    </div>
  );
};

export default Card3;
