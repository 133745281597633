
import React from 'react'

const Solutions = (props) => {
    const { id, image, text } = props
    return (
        <div class="rounded-3xl shadow  dark:border-gray-700  bg-[#6161fd] wow animate__animated animate__fadeInUp" data-wow-duration="1.5s" data-wow-offset="80">
            <img class="rounded-t-lg h-fit" src={image} alt="" />
            <div class="p-5">
                <h5 class="mb-2 mx-auto text-[20px] font-bold tracking-tight text-black text-center" data-wow-delay="1s">{text}</h5>
            </div>
        </div>
    )
}

export default Solutions