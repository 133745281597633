import React from "react";
import img from "../images/abot.png";

const About = () => {
  return (
    <div>
      <div className="flex justify-end w-full h-screen relative">
        <div className="w-[499px] h-[480px] rounded-[499px] border border-black bg-[#acacf799] filter blur-[190px] absolute md:right-0 right-40  top-40 md:top-80 lg:block " />
        <div className="w-[499px] h-[480px] rounded-[499px] border border-black bg-[#acacf799] filter blur-[80px] absolute -left-[20rem] top-[60rem] lg:block hidden" />
        <div className="flex items-center">
          <div className="absolute md:-left-40 md:-top-10 wow animate__animated animate__pulse md:mt-48">
            <img
              src={img}
              className="opacity-20 xl:opacity-100 xl:flex "
              alt="rlvnt logo"
            />
          </div>
          <div className="md:text-left text-center md:justify-center">
            <p
              className=" sm:text-[75px] text-[45px] md:leading-[100px]  md:max-w-[660px] md:w-auto font-extralight  text-[#FFF3F3] dark:text-white wow animate__animated animate__fadeInDown"
              data-wow-iteration="1"
              data-wow-offset="100"
              data-wow-delay=".5s"
            >
              <span className="font-bold">Leading</span> The Digital{" "}
              <span className="font-bold">Solution</span> Revolution.
            </p>
          </div>
        </div>
      </div>
      <div className="container md:mt-80  mx-auto">
        <div className="w-[499px] h-[480px] rounded-[499px] border border-black bg-[#acacf799] filter blur-[100px] absolute md:right-0 right-40 top-[100rem] lg:block" />
        <div className="text-[#FFFFFF] xl:mr-48 text-center xl:text-right">
          <h1
            data-wow-iteration="1"
            data-wow-offset="30"
            data-wow-delay=".5s"
            className="md:text-6xl  py-6 font-extralight wow animate__animated animate__fadeInDown">
            Who
            <span className="font-bold "> We </span>
            Are?
          </h1>
          <p
            data-wow-iteration="1"
            data-wow-offset="50"
            data-wow-delay="1s"
            className="md:text-2xl text-base px-4 md:px-0 wow  animate__animated animate__fadeIn">
            We Are Techdarshak Software LLP, A Company That Specialises In{" "}
            <br />
            Customised Software Solutions For Various Business Needs. We Are{" "}
            <br />
            Passionate About Creating Innovative And Effective Software That Can{" "}
            <br /> Help You Achieve Your Goals And Grow Your Business.
          </p>
        </div>

        <div className="text-[#FFFFFF] px-2 md:px-0 md:text-left md:ml-48 md:mt-48 text-center mt-24">
          <h1
            data-wow-iteration="1"
            data-wow-offset="50"
            data-wow-delay=".5s"
            className="md:text-6xl py-6 font-extralight md:text-md wow animate__animated animate__fadeInDown">
            What We Do <span className="font-bold ">BEST?</span>{" "}
          </h1>
          <p
            data-wow-iteration="1"
            data-wow-offset="50"
            data-wow-delay=".5s"
            className="md:max-w-[968px] md:w-auto  md:text-2xl text-base px-4 md:px-0 wow animate__animated animate__fadeIn">
            We Offer Customised Software Solutions For Accouning, Inventory
            Management,
            And Business Process Automation. These Solutionns Are Tailored
            To Your Specific
            Industry And Sector. We Have Worked With Clients From Various
            Industries And  Sectors, Such As Manufacturing, Retail,
            Education, Healthcare, And More. We
            Have Received Positive Feedback And Testimonials From Our
            Clients, Who Have
            Seen Significant Improvements In Their Business Performance And
            Outcomes.
          </p>
        </div>
        <div className="text-[#FFFFFF] md:mr-48 text-center md:text-right md:mt-32 mt-24">
          <button
            data-wow-iteration="1"
            data-wow-offset="70"
            data-wow-delay=".5s"
            className="bg-[#4848FF] md:hover:bg-[#9747FF] w-[305px] text-xl  md:ml-2 h-16 font-bold rounded-2xl shadow-md hover:shadow-[none] md:hover:text-[black] md:hover:text-xl shadow-[#FFFFFF] wow animate__animated animate__fadeIn ">
            Explore Our Solutions
          </button>
        </div>
      </div>
      <div style={{ fontWeight: "200" }} className=" mx-auto w-fit mt-24 mb-28 md:mt-56 text-center ">
        <h1
          data-wow-iteration="1"
          data-wow-offset="90"
          data-wow-delay=".5s"
          className="md:w-full px-4 md:px-0 md:text-3xl text-3xl wow animate__animated  animate__fadeInDown text-white">
          We Are Always Ready To Take On New <span className="font-bold">Challenges</span> And Opportunities.
        </h1>
        <p
          data-wow-iteration="1"
          data-wow-offset="80"
          data-wow-delay=".5s"
          className="md:max-w-[1134px] md:w-auto px-4 md:px-0 md:text-3xl text-lg wow animate__animated  animate__fadeIn text-white mt-8">
          If You Are Interested In Working With Us Or Learning More About Our
          Services, Please Contact Us Or Book A Free Consultation With Us. We
          Would Love To Hear From You And Help You <span className="font-bold">Grow</span> Your Business With
          <span className="font-bold"> Customized</span> Software Solutions..
        </p>
        <div className="md:text-end text-center">
          <button
            data-wow-iteration="1"
            data-wow-offset="80"
            data-wow-delay=".5s"
            className="text-black font-medium text-xl md:ml-auto mt-20 mb-20 rounded-full p-4 md:w-96 w-72 content-center bg-[#88A7FF] wow animate__animated animate__fadeIn">
            Book A Call Today!
          </button>
        </div>
      </div>
    </div>


  );
};
export default About;
