import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { email, phoneNumber } from '../utils/constants';
import { FaWhatsapp } from 'react-icons/fa';
import { IoMdCall, IoMdClose } from 'react-icons/io';
import { MdAdsClick } from "react-icons/md";


const Contactus = () => {
  const hrefOfEmail = `mailto:${email}`
  const [isModal, setIsModal] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    company: '',
    message: '',
    agreement: false,
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Validation for First Name
    if (!formData.firstName.trim()) {
      newErrors.firstName = 'First Name is required';
      valid = false;
    }

    // Validation for Last Name
    if (!formData.lastName.trim()) {
      newErrors.lastName = 'Last Name is required';
      valid = false;
    }

    // Validation for Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim() || !emailRegex.test(formData.email.trim())) {
      newErrors.email = 'Valid Email is required';
      valid = false;
    }

    // Validation for Phone Number
    const phoneRegex = /^\+91[6-9]\d{0,9}$/;
    if (!formData.phone.trim() || !phoneRegex.test(formData.phone.trim())) {
      newErrors.phone = 'Valid Phone Number is required';
      valid = false;
    }

    // Validation for Message
    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
      valid = false;
    }

    // Validation for Agreement
    if (!formData.agreement) {
      newErrors.agreement = 'You must agree to the terms';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Perform form submission logic here
      console.log('Form submitted successfully:', formData);
      toast.success("Form submitted successfully!");
    } else {
      console.log('Form validation failed:', errors);
      toast.error("Please fill the required fields!");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let fieldValue = value;

    // Validation regex patterns
    const regexPatterns = {
      firstName: /^[a-zA-Z]+$/,
      lastName: /^[a-zA-Z]+$/,
      email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      // phone: /^\+91[6-9]\d{0,9}$/,
      company: /^[a-zA-Z]+$/
    };


    if (regexPatterns[name] && value.trim() !== '' && !regexPatterns[name].test(value.trim())) {
      return; // Do not update state for invalid values
    }

    setFormData({
      ...formData,
      [name]: fieldValue,
    });

  };

  const handleCheckboxChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  const handleCallClick = () => {
    const telLink = `tel:${phoneNumber}`;
    window.location.href = telLink;
  };

  const handleWhatsappClick = () => {
    const whatsappLink = `whatsapp://send?phone=${phoneNumber}`;
    window.location.href = whatsappLink;
  };

  return (
    <div className='max-w-full md:ml-[1%]'>
      <dialog open={isModal} className={`absolute flex flex-col top-[36rem] left-0 w-80 rounded-2xl h-1/2 bg-gradient-to-br from-[#020225] to-slate-400 p-4 z-50 md:hidden ${isModal ? "flex" : "hidden"}`}>
        <div>
          <h1 onClick={() => setIsModal(false)} className='flex justify-end w-full items-start cursor-pointer'><IoMdClose className='rounded-full text-white' /></h1>
        </div>
        
        <div className='flex flex-col mx-auto gap-y-8 my-10 text-center'>
          <div></div><h2 className='text-4xl flex gap-x-2 items-center' onClick={handleCallClick}><IoMdCall color='blue' />Call </h2>
          <h2 className='text-4xl flex gap-x-2 items-center' onClick={handleWhatsappClick}><FaWhatsapp color='green' /> whatsapp</h2>
        </div>
        
      </dialog>
      <ToastContainer />
      <div className="w-[499px] h-[480px] rounded-[499px] border border-black bg-[#acacf799] filter blur-[200px] md:blur-[180px] absolute right-0 md:top-80 top-[11rem] lg:block" />
      <div data-wow-iteration="1" data-wow-offset="10" data-wow-delay=".5s" className='mt-40 mb-36 xl:ml-[17rem] text-center xl:text-left flex flex-col gap-y-6 w-auto wow animate__animated animate__zoomIn'>
        <h1 className='text-white xl:text-[85px] text-5xl mb-4 xl:mb-0 font-bold capitalize'>Contact Techdarshak</h1>
        <p className='text-white xl:text-lg mx-4 md:mx-0 font-normal capitalize'>We appreciate your interest in techdarshak. Please select from the option below.</p>
      </div>
      <div className='mb-40 mt-10 xl:ml-[17rem] text-center xl:text-left flex flex-col gap-y-6 w-auto'>
        <h1 data-wow-iteration="1" data-wow-offset="10" data-wow-delay=".5s" className='text-white xl:text-[85px] text-4xl mb-4 xl:mb-0  font-normal capitalize wow animate__animated animate__zoomIn'>General Inquiries</h1>
        <div className='flex justify-between md:my-10 md:w-max gap-x-40 flex-col xl:flex-row w-full gap-y-10 xl:gap-y-0 mx-auto xl:ml-2 items-center'>
          <div data-wow-iteration="1" data-wow-offset="10" data-wow-delay=".5s" className='gap-y-3 flex flex-col wow animate__animated animate__lightSpeedInLeft' onClick={() => setIsModal(true)}>
            <div className='flex items-center justify-center md:justify-start'>
              <div className='mr-1 md:hidden' style={{color:"white",}}><MdAdsClick className='h-7 w-7'/></div>
            <h1 className='text-white flex xl:text-[45px] text-2xl font-bold capitalize'>+91-{phoneNumber}</h1>
            </div>
            <p className='text-white xl:text-lg text-sm font-normal capitalize'>for Telephonic calls or contact using WHATSAPP</p>
          </div>
          <div data-wow-iteration="1" data-wow-offset="10" data-wow-delay=".5s" className='wow animate__animated animate__lightSpeedInRight'>
            <a className='gap-y-3 flex flex-col align-middle ' href={hrefOfEmail}>
              <div className='flex items-center'>
              <div className='mr-2' style={{color:"white",}}><MdAdsClick className='h-7 w-7 md:h-10 md:w-10'/></div>
              <h1 className='text-white xl:text-[45px] text-2xl font-bold flex'>{email}</h1>
              </div>
              <p className='text-white xl:text-lg text-sm font-normal capitalize md:justify-start'>Email us for all business inquiries</p>
            </a>
          </div>
        </div>
      </div>
      {/* form component below  */}
      <div className='xl:ml-[17rem] text-center xl:text-left mt-12rem flex flex-col w-full lg:w-max  mx-auto'>
        <div className="w-[499px] h-[480px] rounded-[499px] border border-black bg-[#acacf799] filter blur-[120px] absolute -left-[20rem] top-[60rem] lg:block" />
        <h1 data-wow-iteration="1" data-wow-offset="30" data-wow-delay=".5s" className='text-white mx-4 xl:text-[45px] text-4xl font-bold capitalize wow animate__animated animate__fadeInDown'>For specific questions, get in touch below</h1>
        <section className=' p-4 md:mb-56 mb-20'>
          <div className="w-[499px] h-[480px] rounded-[499px] border border-black bg-[#acacf799] filter blur-[130px] absolute md:right-0 right-40 top-[100rem] hidden lg:block" />
          <h1 data-wow-iteration="1" data-wow-offset="30" data-wow-delay=".5s" className='text-white mx-4 xl:text-[30px] mt-10 mb-6 text-2xl font-normal wow animate__animated animate__fadeIn'>Send us a question</h1>
          <div className="flex flex-col items-center justify-center py-8 mx-4 md:px-0 lg:py-0">
            <div data-wow-iteration="1" data-wow-offset="50" data-wow-delay=".5s" className="w-full bg-[#8495C7] rounded-3xl shadow border md:mt-0 sm:max-w-[980px] xl:p-0 border-gray-700  wow animate__animated  wow animate__animated animate__fadeIn">
              <div className="md:p-6 space-y-4 md:space-y-6 sm:p-8 max-w-[819px] mx-auto text-left">
                <p className="tracking-normal text-black md:text-base p-4">
                  Thank you for your interest in Techdarshak's services. Please provide the following information about your business needs to help us serve you better. This information will enable us to route your request to the appropriate person. You should receive a response within one to two business days.
                </p>
                <form className="space-y-4 md:space-y-4 px-6 md:py-0 py-8" action="#" onSubmit={handleSubmit}>
                  <div>
                    <label htmlFor="firstName" className="block mb-2 text-base font-medium text-black">First Name <span className='text-red-500'>*</span></label>
                    <input type="text" name="firstName" id="firstName" className={`bg-[#D9D9D9] border border-gray-300 text-black sm:text-sm rounded-lg focus:border-primary-600 block w-full p-2.5 placeholder-gray-400 ${errors.firstName && "border-2 border-red-500"}`}
                      onChange={handleChange}
                      value={formData.firstName}
                    />
                  </div>
                  <div>
                    <label htmlFor="lastName" className="block mb-2 text-base font-medium text-black">Last Name <span className='text-red-500'>*</span></label>
                    <input type="text" name="lastName" id="lastName" className={`bg-[#D9D9D9] border border-gray-300 text-black sm:text-base rounded-lg focus:ring-red-600 focus:border-primary-600 block w-full p-2.5 placeholder-gray-400 ${errors.lastName && "border-2 border-red-500"}`}
                      onChange={handleChange}
                      value={formData.lastName}
                    />
                  </div>
                  <div>
                    <label htmlFor="email" className="block mb-2 text-base font-medium text-black ">Email Address <span className='text-red-500'>*</span></label>
                    <input type="email" name="email" id="email" className={`bg-[#D9D9D9] border border-gray-300 text-black sm:text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 placeholder-gray-400 ${errors.email && "border-2 border-red-500"}`}
                      onChange={handleChange}
                      value={formData.email}
                    />
                  </div>
                  <div>
                    <label htmlFor="phone" className="block mb-2 text-base font-medium text-black">Phone Number <span className='text-red-500'>*</span><small className='text-xs'> (Please include country code)</small></label>
                    <input type="tel" name="phone" id="phone" className={`bg-[#D9D9D9] border border-gray-300 text-black sm:text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 placeholder-gray-400 ${errors.phone && "border-2 border-red-500"}`}
                      onChange={handleChange}
                      value={formData.phone}
                      maxLength={13}
                    />
                  </div>
                  <div>
                    <label htmlFor="company" className="block mb-2 text-base font-medium text-black">Company/Organization </label>
                    <input type="text" name="company" id="company" className="bg-[#D9D9D9] border border-gray-300 text-black sm:text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 placeholder-gray-400 "
                      onChange={handleChange}
                      value={formData.company}
                    />
                  </div>
                  <div>
                    <label htmlFor="message" className="block mb-2 text-base font-medium text-black ">How Can We help you? <span className='text-red-500'>*</span></label>
                    <textarea type='input' id="message" name='message' rows="4" className={`block p-2.5 w-full text-base text-black bg-[#D9D9D9] rounded-lg border placeholder-gray-400 ${errors.message && "border-2 border-red-500"}`}
                      onChange={handleChange}
                      value={formData.message}
                    ></textarea>
                  </div>
                  <div className="flex items-center justify-start ">
                    <div className="flex items-center my-8 md:px-8">
                      <div className="flex items-center h-5 relative">
                        <input id="remember" name='agreement' aria-describedby="remember" type="checkbox" className={`w-8 h-8 rounded-xl bg-[#B4BDD8] appearance-none relative peer shrink-0  checked:bg-blue-800 checked:border-0 ${errors.agreement ? "border-2 outline-red-400 border-red-500" : "border-none"}`} onChange={handleCheckboxChange}
                          checked={formData.agreement} />
                        <svg
                          className="absolute h-4 mt-1 peer-checked:flex justify-center items-center w-full pointer-events-none"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#8495C7"
                          stroke-width="4"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                      </div>
                      <div className="ml-3 text-base">
                        <label htmlFor="remember" className="text-black">I agree to the use or processing of my personal information by Techdarshak Software LLP for the purpose of fulfilling this request.</label>
                      </div>
                    </div>
                  </div>
                  <div className='flex justify-center'>
                    <button type="submit" className="w-fit mx-auto text-white bg-blue-600 hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium  text-sm px-12 py-4 rounded-2xl text-center focus:ring-primary-800">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>

    </div>
  );
};

export default Contactus;
