

import React from 'react'

const Card = (props) => {
    const {id,name,image} = props
  return (
    <div className=" bg-[#6F6FF6] h-44 w-52  lg:h-44 lg:w-48 justify-center items-center flex flex-col mx-8 rounded-3xl m-4 wow animate__animated animate__jackInTheBox" 
    data-wow-duration="1s" data-wow-offset="60"
    >
    <div>
      <img className='h-20 w-20' src={image} />
    </div>
    <div className="alignheight">
      <h2 className="align text-center w-36 lg:text-xl text-2xl">{name}</h2>    
      </div>
  </div>
  )
}

export default Card